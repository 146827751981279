import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)" >


<path d="M2033 3958 c11 -86 51 -121 100 -84 39 30 1 96 -73 123 l-33 12 6
-51z"/>
<path d="M1740 3963 c1 -27 27 -56 45 -50 21 9 19 44 -4 57 -28 15 -41 12 -41
-7z"/>
<path d="M2335 3958 c10 -33 31 -52 49 -45 28 11 19 44 -15 56 -42 14 -41 15
-34 -11z"/>
<path d="M2230 3926 c0 -92 -55 -163 -118 -152 -52 8 -110 51 -144 107 -17 27
-32 49 -33 49 -1 0 9 -33 23 -72 19 -57 23 -88 20 -141 -3 -63 -7 -72 -36 -98
-45 -40 -100 -39 -178 2 -33 17 -68 38 -79 48 -42 37 -45 31 -9 -15 65 -86 89
-149 88 -234 0 -58 -6 -87 -26 -130 -50 -109 -73 -222 -72 -370 0 -208 44
-353 159 -526 55 -83 167 -197 246 -251 108 -74 250 -131 383 -154 86 -14 312
-6 381 15 129 38 252 97 338 163 459 353 506 1000 104 1416 -197 203 -523 320
-776 277 -66 -11 -81 -10 -134 6 -40 12 -74 31 -99 55 l-38 36 0 -31z m615
-391 c351 -92 381 -508 55 -771 -175 -141 -362 -195 -464 -133 l-31 19 -12
-58 c-7 -31 -25 -113 -39 -182 -14 -69 -29 -129 -34 -134 -14 -14 -59 10 -72
39 -28 63 82 500 208 822 69 175 80 195 111 191 14 -2 35 -15 46 -30 l21 -28
-33 -72 c-50 -113 -81 -198 -71 -198 5 0 12 5 15 10 8 13 43 3 68 -18 23 -20
23 -69 -1 -76 -26 -9 -97 -76 -126 -120 -14 -21 -26 -52 -26 -69 l0 -30 53 6
c108 11 259 92 364 196 105 104 155 210 156 331 1 65 -2 81 -25 116 -93 144
-308 148 -583 11 -104 -51 -158 -89 -221 -153 -61 -60 -89 -108 -85 -146 2
-25 -1 -28 -28 -28 -58 0 -85 95 -48 167 21 40 143 153 213 198 190 122 435
180 589 140z"/>
<path d="M1596 3858 c16 -54 64 -72 64 -24 0 15 -9 27 -25 34 -38 18 -47 15
-39 -10z"/>
<path d="M1740 3816 c0 -14 27 -76 42 -98 23 -33 57 -36 81 -10 35 39 15 78
-52 101 -33 11 -71 15 -71 7z"/>
<path d="M1600 1726 c0 -8 7 -16 15 -20 12 -4 15 -28 15 -126 0 -98 -3 -122
-15 -126 -8 -4 -15 -12 -15 -20 0 -11 17 -14 70 -14 53 0 70 3 70 14 0 7 -9
16 -20 19 -16 4 -20 14 -20 46 0 40 1 41 36 41 64 0 104 32 110 88 10 80 -36
110 -168 111 -60 1 -78 -2 -78 -13z m150 -26 c49 -26 28 -130 -25 -130 -24 0
-25 2 -25 70 0 74 7 83 50 60z"/>
<path d="M1940 1725 c0 -8 6 -15 14 -15 8 0 17 -4 20 -8 8 -13 -73 -236 -90
-249 -25 -18 -7 -33 41 -33 49 0 66 15 40 34 -14 10 -15 16 -6 34 10 18 21 22
61 22 49 0 50 -1 50 -29 0 -16 -5 -31 -12 -33 -6 -2 -8 -10 -4 -16 10 -16 126
-16 126 -1 0 7 -6 17 -14 23 -8 6 -34 72 -58 146 l-44 135 -62 3 c-51 2 -62 0
-62 -13z m103 -127 l16 -58 -45 0 c-24 0 -44 1 -44 3 0 2 7 26 16 53 9 27 18
55 20 63 7 22 19 2 37 -61z"/>
<path d="M2200 1725 c0 -8 4 -15 9 -15 5 0 32 -40 60 -90 57 -100 68 -158 31
-167 -11 -3 -20 -12 -20 -19 0 -11 17 -14 70 -14 53 0 70 3 70 14 0 7 -9 16
-20 19 -35 9 -26 74 21 164 23 43 48 83 56 90 23 18 6 33 -37 33 -37 0 -66
-20 -42 -28 17 -5 15 -27 -8 -72 -28 -55 -36 -56 -60 -11 -22 45 -26 77 -8 83
6 2 8 10 4 16 -4 7 -31 12 -67 12 -46 0 -59 -3 -59 -15z"/>
<path d="M2578 1721 c-59 -47 -33 -122 59 -175 58 -33 68 -48 53 -77 -20 -36
-79 -19 -105 31 -21 41 -45 39 -45 -2 0 -69 4 -73 81 -76 102 -4 139 20 139
87 0 38 -24 68 -80 96 -58 29 -76 55 -60 86 17 31 60 22 85 -18 27 -44 45 -42
45 5 0 20 -4 42 -8 47 -4 6 -36 11 -72 13 -54 3 -71 -1 -92 -17z"/>
<path d="M2810 1726 c0 -8 7 -16 15 -20 12 -4 15 -26 15 -112 l0 -106 34 -34
c28 -28 42 -34 75 -34 48 0 96 21 111 49 5 11 10 67 10 126 0 85 3 107 15 111
8 4 15 12 15 20 0 20 -96 19 -104 -1 -3 -9 0 -15 9 -15 12 0 15 -20 15 -113 0
-75 -4 -117 -12 -125 -20 -20 -76 -15 -88 7 -13 26 -13 231 0 231 6 0 10 7 10
15 0 12 -13 15 -60 15 -45 0 -60 -4 -60 -14z"/>
<path d="M3150 1726 c0 -9 5 -16 10 -16 6 0 10 -48 10 -124 0 -73 -4 -127 -10
-131 -5 -3 -10 -13 -10 -22 0 -14 13 -15 95 -11 101 6 139 20 154 62 15 37 1
75 -31 92 l-30 15 27 25 c33 31 30 69 -7 97 -38 28 -208 39 -208 13z m158 -28
c14 -14 16 -57 4 -76 -5 -7 -22 -14 -38 -15 -28 -2 -29 -1 -32 51 -3 51 -3 52
25 52 16 0 34 -5 41 -12z m6 -144 c9 -8 16 -28 16 -44 0 -37 -24 -60 -61 -60
-29 0 -29 0 -29 60 0 60 0 60 29 60 16 0 37 -7 45 -16z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
